<template>
  <div
    class="full flex-column no-expand"
    style="padding: 0.4rem; box-sizing: border-box; background: #fff"
  >
    <div
      class="flex-row cart-title common-card three-d-shadow-mid"
      style="background: #fff"
    >
      <div class="back-circle">
        <van-icon name="bag-o" />
      </div>
      <span
        >共有{{ getTotalCountAndPrice.count }}道菜，总计{{
          getTotalCountAndPrice.price
        }}元</span
      >
      <van-button
        @click="clearCart"
        round
        type="primary"
        size="small"
        style="margin-left: auto"
        >清空</van-button
      >
    </div>
    <div
      style="flex: 1; margin-top: 0.4rem; padding: 0.4rem 0"
      class="flex-column no-expand"
    >
      <div class="no-expand" style="flex: 1; padding-bottom: 10px">
        <scroll ref="scroll">
          <div>
            <div
              v-for="(item, index) in categoryGoods"
              :key="index"
              class="card-detail"
            >
              <div
                style="display: flex; align-items: center; font-size: 0.8rem"
              >
                <van-icon name="completed" /><span style="font-size: 0.6rem">{{
                  searchCateName(item[0].cate_id)
                }}</span>
              </div>
              <divider
                :style="{
                  borderColor: '#b0baba',
                  margin: '7px 0px',
                  width: '100%',
                }"
              />
              <template v-for="food in item">
                <template v-for="user in food.userStock">
                  <div :key="user.id">
                    <!--<Card
                      style="background-color: transparent"
                      :price="
                        (food.member_price ? food.member_price : food.price) /
                        100
                      "
                      :origin-price="
                        food.member_price ? food.price / 100 : undefined
                      "
                      :desc="food.introduce"
                      :title="food.name"
                      :thumb="food.img_url"
                    >
                      <template #footer>
                        <div
                          class="flex-row"
                          style="
                            width: 100%;
                            padding-top: 0.4rem;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <div class="flex-row" style="align-items: center">
                            <van-image
                              round
                              width="1.5rem"
                              height="1.5rem"
                              fit="cover"
                              :src="user.user.avatar"
                            />
                            <span style="margin-left: 0.2rem">{{
                              user.user.nickname
                            }}</span>
                          </div>
                          <stepper
                            theme="round"
                            min="0"
                            button-size="22"
                            disable-input
                            async-change
                            :disabled="food.loading"
                            :value="user.num"
                            :max="food.stock.toString()"
                            @plus="changeGoodNum(user, food, 'plus')"
                            @minus="
                              changeGoodNum(user, food, 'minus', user.user)
                            "
                          />
                        </div>
                      </template>
                    </Card>-->
                    <div style="border-radius: 0.3rem; padding: 0.3rem">
                      <div
                        class="flex-row"
                        style="justify-content: space-between"
                      >
                        <div
                          style="padding-left: 0.6rem; padding-right: 0.3rem"
                        >
                          <div style="position: relative">
                            <div
                              class="user-avatar"
                              :style="{
                                'background-image': `url('${user.user.avatar}')`,
                              }"
                            ></div>
                            <div
                              style="
                                display: flex;
                                border-radius: 8px;
                                overflow: hidden;
                              "
                            >
                              <van-image
                                width="3rem"
                                height="2.8rem"
                                fit="cover"
                                :src="food.img_url"
                              >
                                <template v-slot:loading>
                                  <van-loading type="spinner" size="20" />
                                </template>
                              </van-image>
                            </div>
                          </div>
                        </div>
                        <div
                          class="flex-row no-expand"
                          style="
                            flex: 1;
                            justify-content: space-between;
                            align-items: flex-end;
                            padding: 0.3rem 0 0.1rem 0;
                          "
                        >
                          <div
                            class="storage flex-column"
                            style="justify-content: space-between"
                          >
                            <div class="food-name van-ellipsis">
                              {{ food.name }}
                            </div>
                            <div class="price">
                              <template v-if="food.member_price">
                                <span
                                  >¥&nbsp;{{ food.member_price / 100 }}/份</span
                                >
                                <span
                                  class="lined"
                                  style="text-decoration: line-through"
                                  >¥&nbsp;{{ food.price / 100 }}/份</span
                                >
                              </template>
                              <template v-else>
                                <span>¥&nbsp;{{ food.price / 100 }}/份</span>
                              </template>
                            </div>
                          </div>
                          <stepper
                            theme="round"
                            min="0"
                            button-size="22"
                            disable-input
                            async-change
                            style="flex-shrink: 0"
                            :disabled="food.loading"
                            :value="user.num"
                            :max="food.stock.toString()"
                            @plus="changeGoodNum(user, food, 'plus')"
                            @minus="
                              changeGoodNum(user, food, 'minus', user.user)
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
        </scroll>
      </div>
      <div>
        <van-field
          style="
            border-radius: 20px;
            background-color: rgba(255, 255, 255, 0.6);
          "
          v-model="message"
          rows="1"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="请输入备注"
          show-word-limit
          @blur="$refs.scroll.refresh()"
        />
      </div>
    </div>

    <div class="flex-row no-shrink" style="padding: 0.4rem 0">
      <div class="cart-button">
        <van-button
          style="width: 100%; border-radius: 0.4rem"
          type="primary"
          to="shopping"
          plain
          :loading="buttonLoading"
          >继续点单</van-button
        >
      </div>
      <div class="cart-button">
        <van-button
          style="width: 100%; border-radius: 0.4rem"
          type="primary"
          :disabled="getTotalCountAndPrice.count < 1"
          @click="order"
          :loading="buttonLoading"
          >现在下单</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  Button as VanButton,
  Divider,
  Loading as VanLoading,
  Image as VanImage,
  Icon as VanIcon,
  Stepper,
  Field as VanField,
} from "vant";
import { createOrder, takeOrder } from "@/api/base";
import { mapGetters, mapState } from "vuex";
import Scroll from "@/components/scroll";
import wx from "weixin-js-sdk";
export default {
  name: "Cart",
  components: {
    Scroll,
    VanButton,
    Divider,
    VanIcon,
    VanImage,
    Stepper,
    VanField,
    VanLoading,
  },
  data() {
    return {
      loading: false,
      message: "",
      buttonLoading: false,
    };
  },
  computed: {
    categoryGoods() {
      const map = {};
      this.goods.forEach((val) => {
        if (map[val.cate_id]) {
          map[val.cate_id].push(val);
        } else {
          map[val.cate_id] = [val];
        }
      });
      return map;
    },
    ...mapGetters("cart", ["getTotalCountAndPrice"]),
    ...mapState({
      goods: (state) => state.cart.goods,
      menu: (state) => state.base.totalGoods,
    }),
  },
  methods: {
    clearCart() {
      this.$store.dispatch("cart/checkout", { type: "clear" });
    },
    searchCateName(cateId) {
      let name = "";
      for (let item of this.menu) {
        if (item.id === cateId) {
          return item.name;
        }
      }
      return name;
    },
    changeGoodNum(user, item, type, otherUser) {
      user.loading = true;
      this.$store
        .dispatch("cart/checkout", { good: item, type, user: otherUser })
        .then(() => {
          user.loading = false;
          this.showDetail = false;
        })
        .catch(() => {
          this.$toast.fail("点菜失败，请稍后再试");
        });
    },
    order() {
      this.buttonLoading = true;
      const orderDetails = this.goods.map((value) => ({
        goods_id: value.id,
        total_count: value.total,
      }));
      const order_id = this.$store.state.base.orderId;
      const remarks = this.message;
      //如果支付后才打单
      takeOrder({ order_id, orderDetails, remarks })
        .then(() => {
          if (this.$store.state.base.currentRestaurant.isPayPrint) {
            this.pay();
          } else {
            this.$dialog
              .alert({
                message: "提交订单成功！",
              })
              .then(() => {
                this.buttonLoading = false;
                this.clearCart();
                if (this.$store.state.app.ws) {
                  this.$store.state.app.ws.send(
                    JSON.stringify({ type: "order" })
                  );
                }
                this.$router.push("/home");
              });
          }
        })
        .catch(() => {
          this.buttonLoading = false;
          this.$toast.fail("下单失败，请稍后再试");
        });
    },
    pay() {
      const that = this;
      this.loading = true;
      createOrder({
        fee: this.getTotalCountAndPrice.price * 100,
        openId: this.$store.state.base.currentUser.openId,
        orderId: this.$store.state.base.orderId,
        merId: this.$store.state.base.merchantId,
      })
        .then((res) => {
          var args = res.data;
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.noncestr,
            signature: res.data.signature,
            jsApiList: ["chooseWXPay"],
          });
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ["chooseWXPay"],
              success: (res) => {
                that.loading = false;
                console.log("checked api:", res);
              },
              fail: (err) => {
                that.buttonLoading = false;
                that.loading = false;
                that.clearCart();
                console.log("check api fail:", err);
              },
            });
          });

          wx.chooseWXPay({
            timestamp: args.timeStamp,
            nonceStr: args.nonceStr,
            package: args.package,
            signType: args.signType,
            paySign: args.sign,
            success: function (res) {
              that.loading = false;
              that.buttonLoading = false;
              that.clearCart();
              if (res.errMsg === "chooseWXPay:ok") {
                that.$store.push({ path: "home", meta: { pay: true } });
              }
            },
            // 支付取消回调函数
            cancel: function () {
              that.buttonLoading = false;
              that.loading = false;
              that.clearCart();
              alert("您已取消本次支付");
              that.$router.push("/home");
            },
            // 支付失败回调函数
            fail: function () {
              that.buttonLoading = false;
              that.loading = false;
              that.clearCart();
              alert("支付失败,请稍后再试");
              that.$router.push("/home");
            },
          });
        })
        .catch(() => {
          that.buttonLoading = false;
          that.loading = false;
          that.clearCart();
          that.$toast.fail("支付失败，请重新支付");
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$nextTick(() => {
        vm.$refs.scroll.refresh();
      });
    });
  },
};
</script>

<style lang="less" scoped>
.user-avatar {
  position: absolute;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-size: cover;
  transform: translate(-50%, -50%);
  border: 1px solid #fff;
  top: 50%;
  left: 0;
  z-index: 3;
  box-shadow: -1px 1px 5px 0px #696969;
}

.cart-title {
  font-size: 0.6rem;
  align-items: center;
  padding: 0.6rem 0.4rem;
}
.cart-button {
  flex: 1;
}
.cart-button:first-child {
  padding: 0 0.2rem 0 0.4rem;
}
.cart-button:last-child {
  padding: 0 0.4rem 0 0.2rem;
}
.card-detail {
  font-size: 0.6rem;
}
</style>
